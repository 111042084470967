export const TYPES_ENCAISSEMENT = [
    "DEAL - AISFT Arrêté",
    "DEAL - AISFT Travaux",
    "DEAL - LBU MOE",
    "DEAL - LBU Travaux",
    "DEAL - LBU Complémentaire",
    "CTM - SUBVENTION",
    "BENEFICIAIRE - APPORT",
    "MARTINIQUE HABITAT",
    "CAF - SUBVENTION"
]
export const TYPES_DECAISSEMENT = [
    "RECRUTEMENT BENEFICIAIRE",
    "ETUDE TECHNIQUE",
    "DIAGNOSTICS (BET)",
    "DECLARATION PREALABLE",
    "HONORAIRES CONDUCTEUR DE TRAVAUX",
    "TRAVAUX DEMARRAGE",
    "TRAVAUX TRANCHE 1",
    "TRAVAUX TRANCHE 2",
    "TRAVAUX SOLDE"
]
export const TYPES_DECAISSEMENT_HORS_PLAN = [
    "MOE",
    "AISFT",
    "FRAIS MARTINIQUE HABITAT",
];

export const TYPES_DECAISSEMENT_ETUDE = [
    "RECRUTEMENT BENEFICIAIRE",
    "ETUDE TECHNIQUE",
    "DIAGNOSTICS (BET)",
    "DECLARATION PREALABLE",
    "HONORAIRES CONDUCTEUR DE TRAVAUX",
]
export const PROVENANCES = ["DEAL", "CTM", "Martinique Habitat", "CAF", "CGSS", "Bénéficiaire", "Espace Sud", "CCAS"]